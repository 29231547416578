<template>
  <v-container min-width="200">
    <v-row justify="center">
      <v-col cols="2">
        <h1 class="text-center text-no-wrap">{{ value }}</h1>
      </v-col>
      <v-col v-if="unit" cols="2">
        <h1 class="text-center">{{ unit }}</h1>
      </v-col>
      <v-col v-if="iconUrl" cols="2">
        <v-img
          cover
          aspect-ratio="1"
          :src="iconUrl"
          :lazy-src="iconUrl"
          max-width="48"
          max-height="48"
        ></v-img>
      </v-col>
      <v-col>
        <v-slider
          v-model="value"
          :min="min"
          :max="max"
          :step="step"
          ticks
          :tick-labels="ticksLabels"
        >
          <template v-slot:prepend>
            <v-icon @click="decrement">mdi-minus</v-icon>
          </template>
          <template v-slot:append>
            <v-icon @click="increment">mdi-plus</v-icon>
          </template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row justify="center">
      {{ title }}
    </v-row>
    <v-row justify="center">
      {{ description }}
    </v-row>
  </v-container>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "RoomControlSlider",
  data: () => ({
    value: 0,
    lastSentValue: 0,
  }),
  computed: {
    ticksLabels() {
      const steps = Math.floor((this.max - this.min) / this.step);
      const placeholder = [...Array(steps - 1).keys()].map((el) => "");
      return [this.min.toString(), ...placeholder, this.max.toString()];
    },
  },
  mounted() {
    this.lastSentValue = this.state || this.min;
    this.value = this.state || this.min;
  },
  watch: {
    value(value) {
      if (
        this.min <= value &&
        value <= this.max &&
        this.lastSentValue != value
      ) {
        this.lastSentValue = value;
        this.$emit("change", { commandId: this.commandId, value });
      }
    },
  },
  methods: {
    increment() {
      this.value++;
    },
    decrement() {
      this.value--;
    },
  },
  props: {
    commandId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    iconUrl: {
      type: String,
      default: "",
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: "",
    },
    state: {
      type: Number,
      default: 0,
    },
  },
};
</script>
