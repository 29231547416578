<template>
  <RoomControlButton
    v-if="command.actionType === 'button'"
    :commandId="command.id"
    :title="title"
    :description="description"
    :iconUrl="iconUrl"
    :state="commandState"
    @change="changeEventHandler"
  ></RoomControlButton>
  <RoomControlToggleButton
    v-else-if="command.actionType === 'toggle-button'"
    :commandId="command.id"
    :title="title"
    :description="description"
    :onValue="onValue"
    :offValue="offValue"
    :iconUrl="iconUrl"
    :state="commandState"
    @change="changeEventHandler"
  ></RoomControlToggleButton>
  <RoomControlSlider
    v-else-if="command.actionType === 'slider'"
    :commandId="command.id"
    :title="title"
    :description="description"
    :iconUrl="iconUrl"
    :min="command.min"
    :max="command.max"
    :step="command.step"
    :unit="command.unit"
    :state="commandState"
    @change="changeEventHandler"
  ></RoomControlSlider>
</template>

<script>
import { mapState } from "vuex";
import throttle from "lodash/throttle";
import { ROOT } from "@/config";
import RoomControlButton from "./element/RoomControlButton.vue";
import RoomControlToggleButton from "./element/RoomControlToggleButton.vue";
import RoomControlSlider from "./element/RoomControlSlider.vue";

export default {
  name: "Command",
  components: {
    RoomControlButton,
    RoomControlToggleButton,
    RoomControlSlider,
  },
  computed: {
    ...mapState("session", ["authResult"]),
    ...mapState("roomControl", ["state"]),
    i18n() {
      return (
        this.command.i18n.filter(
          (el) => el.language === this.$config.language_key.toLowerCase()
        )[0] || {}
      );
    },
    title() {
      return this.i18n.title;
    },
    description() {
      return this.i18n.description;
    },
    onValue() {
      return this.i18n.onValue;
    },
    offValue() {
      return this.i18n.offValue;
    },
    iconUrl() {
      return this.command.iconImage
        ? `${ROOT}media/${this.command.iconImage.propertyId}/${this.command.iconImage._id}.${this.command.iconImage.ext}`
        : "";
    },
    commandState() {
      const room = this.authResult.checkin[0].room;
      return this.state[`${room}-${this.command.id}`];
    },
  },
  methods: {
    changeEventHandler({ commandId, value }) {
      this.throttleExec(() => {
        this.$store.dispatch("roomControl/setRoomControl", {
          item: { id: commandId, value },
        });
      });
    },
    throttleExec: throttle((fn) => fn(), 500),
  },
  props: {
    command: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
